import _ from 'lodash';
import { ApiError, NotFoundError, ServerError, UnauthenticatedError, UnauthorizedError } from '../../errors';
// tslint:disable-next-line: no-submodule-imports
import { Router } from '@vaadin/router/dist/vaadin-router';
import { OktaAuthService } from '../auth';

export class CCApiService {
  authEnabled = false;
  oktaService: OktaAuthService;

  constructor(authClientId?: string, authIssuerUri?: string) {
    if (authClientId && authIssuerUri) {
      this.authEnabled = true;
      this.oktaService = new OktaAuthService(authClientId, authIssuerUri);
    }
  }

  performFetch = async (url: string, request?: RequestInit): Promise<any> => {
    request = request || { method: 'GET' };

    const headers: any = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    };

    if (this.authEnabled) {
      const tokens = await this.oktaService.getTokens();
      if(!tokens.accessToken?.tokenType || !tokens.accessToken?.accessToken) {
        const missingAccessTokenMessage = 'Unable to get access token for Authorization header (auth is enabled)';
        console.error(missingAccessTokenMessage);
        return Promise.reject(missingAccessTokenMessage);
      }
      headers.Authorization = `${tokens.accessToken.tokenType} ${tokens.accessToken.accessToken}`;
    }

    request.headers = _.defaults(request.headers || {}, headers);

    let response: Response;
    try {
      response = await fetch(url, request);
    } catch (err) {
      console.error(err);

      // Low-level handling for verifying auth token whenever fetch is failed, the more specific
      // unauthenticated case will be handled post-fetch via `handle401()`
      this.oktaService.authGuard();
      throw new Error(err);
    }

    let json: any;
    try {
      const text = await response.text();
      json = text ? JSON.parse(text) : null;
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        this.handle401();
      }
      throw error;
    }
    return json;
  };

  handle401() {
    Router.go('error/401');
  }

  async parseJson(response: Response): Promise<any> {
    switch (response.status) {
      case 200:
      case 201:
        const json = await response.json();
        return Promise.resolve(json);
      case 204:
        return Promise.resolve();
      case 401:
        throw new UnauthenticatedError();
      case 403:
        throw new UnauthorizedError();
      case 404:
        throw new NotFoundError();
      case 500:
        throw new ServerError();
      default:
        const errorJson = await response.json();
        throw errorJson.errorCode ? new ApiError(errorJson.errorCode) : new Error(errorJson.message);
    }
  }
}
